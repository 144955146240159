h1.index_pageTitle_1-ovu {
  font: normal normal 500 24px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 21px;
}
h2.index_cardTitle_Cx3-0, .card > .card-header > h2 {
  padding: 10px 20px;
  font: normal normal 500 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  border-left: 4px solid #5068C2;
}
.ant-btn {
  font: normal normal 500 14px/30px "Noto Sans JP", sans-serif;
}
.ant-btn.index_filledBtn_2Fv58 {
    background: #5068C2 0% 0% no-repeat padding-box !important;
    border-style: none !important;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal 500 14px/30px "Noto Sans JP", sans-serif !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    padding: 0 18px;
}
.ant-btn.index_filledBtn_2Fv58 span {
      color: #FFFFFF !important;
}
.ant-btn.index_filledBtn_2Fv58:hover {
      opacity: 0.2;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_2Fv58) {
    color: #5068C2 !important;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8DA;
    border-radius: 4px;
    opacity: 1;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_2Fv58):hover {
      background: #EFEFF7 0% 0% no-repeat padding-box;
      border: 1px solid #5068C2 !important;
      border-radius: 4px;
      opacity: 1;
}
.ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f2f4f8 !important;
    box-shadow: none !important;
    pointer-events: none;
}
.ant-popover-buttons button {
  line-height: 24px !important;
}
.ant-pagination-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8DA;
  border-radius: 4px;
}
.ant-pagination-item:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #5068C2;
    border-radius: 4px;
}
.ant-pagination-item-active {
  background: #EFEFF7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #5068C2;
}
.ant-list-pagination {
  text-align: center;
}
.ant-pagination {
  text-align: center !important;
}
.ant-list-item:hover {
  background: #EFEFF7 0% 0% no-repeat padding-box;
}
.ant-list-item .ant-list-item-action-split {
  width: 0;
}
.card > .card-header {
  padding-left: 0 !important;
}
.card .card-body {
  padding: 16px !important;
}
@media screen and (max-width: 767px) {
.card .card-body {
      padding-left: 12px !important;
}
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner {
  border-color: #BBBBBB !important;
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner::after {
    border-color: #BBBBBB !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner {
  border-color: #5068C2 !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner::after {
    background-color: #5068C2 !important;
    border-color: #5068C2 !important;
}
.ant-tabs-nav {
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  color: #3042A0;
  font: normal normal 500 14px/20px "Noto Sans JP", sans-serif;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3042A0;
    background: #EFEFF7 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
}
.ant-tabs-nav .ant-tabs-tab-active {
    background: #3042A0 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    color: #FFF;
}
.ant-tabs-nav .ant-tabs-tab-active:hover {
      background: #3042A0 0% 0% no-repeat padding-box;
      color: #FFF;
}
.ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #3042A0 !important;
}
.ant-checkbox-group .ant-checkbox-wrapper {
  font: normal normal normal 14px/33px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
    border: 1px solid #BBBBBB;
    border-radius: 2px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5068C2;
    border-color: #5068C2;
}
.ant-form .ant-form-item > .ant-form-item-label > label {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
span.index_requiredMark_3GEG6 {
  display: inline-block;
  width: 36px;
  height: 20px;
  background: #EF5350 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
  font: normal normal normal 11px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  margin: 8px;
  padding: 1px 6px;
}
.ant-table-wrapper table {
  table-layout: fixed;
  word-break: break-all;
  border-collapse: separate;
  border-radius: 4px;
  border: none !important;
}
.ant-table-wrapper table th {
    border: none !important;
}
.ant-table-wrapper tr {
  font: normal normal 300 14px/14px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-table-wrapper tr.index_stripe0_ysWkB {
    background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe0_ysWkB td.ant-table-column-sort {
      background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_3eBxC {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_3eBxC td.ant-table-column-sort {
      background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr td {
    border: none;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter {
  vertical-align: top;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter .on {
    color: #5068C2 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-small .ant-table-body {
    margin: 0;
}
.ant-table-small .ant-table-body table {
      border-collapse: separate;
      border-radius: 4px;
}
.ant-table-small .ant-table-body table th {
        font: normal normal 500 12px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
        background: #EFF0F3 0% 0% no-repeat padding-box !important;
        height: 36px !important;
        padding: 0px 10px !important;
        border: none !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table th {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table td {
        font: normal normal 300 14px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        padding: 0px 10px !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table td {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table tr.index_stripe_1IbqA {
        background: #FAFBFC 0% 0% no-repeat padding-box;
}
.index_infoCard_6hVf8 {
  color: #74708d;
  background-color: #fff;
  padding: 1.53rem;
  border-radius: 3px;
  display: block;
  margin-bottom: 1.53rem;
}
@media (max-width: 991px) {
.index_infoCard_6hVf8 {
      margin-bottom: 1.23rem !important;
      margin-top: 0 !important;
}
}
.index_infoCard_6hVf8.index_default_1pDBO, .index_infoCard_6hVf8.index_primary_1ufxk, .index_infoCard_6hVf8.index_secondary_1bpoq, .index_infoCard_6hVf8.index_success_2cePa, .index_infoCard_6hVf8.index_info_2o2nT, .index_infoCard_6hVf8.index_warning_2nTVa, .index_infoCard_6hVf8.index_danger_2c7PV {
    color: #fff;
}
.index_infoCard_6hVf8.index_empty_1EvVz {
    border: 1px solid #e4e9f0;
}
.index_infoCard_6hVf8.index_default_1pDBO {
    background: #acb7bf;
}
.index_infoCard_6hVf8.index_primary_1ufxk {
    background: #0190fe;
}
.index_infoCard_6hVf8.index_secondary_1bpoq {
    background: #6a7a84;
}
.index_infoCard_6hVf8.index_success_2cePa {
    background: #46be8a;
}
.index_infoCard_6hVf8.index_info_2o2nT {
    background: #0887c9;
}
.index_infoCard_6hVf8.index_warning_2nTVa {
    background: #f39834;
}
.index_infoCard_6hVf8.index_danger_2c7PV {
    background: #fb434a;
}
.index_infoCard_6hVf8.index_disabled_2phyg {
    cursor: not-allowed;
    opacity: 0.65;
}
.index_infoCard_6hVf8.index_squared_OZfJD {
    border-radius: 0;
}
.index_digit_1Mxhj {
  float: left;
  margin-right: 1.53rem;
  font-size: 3.23rem;
  width: 4.3rem;
  line-height: 3.84rem;
  text-align: center;
  font-weight: bold;
}
.index_digit_1Mxhj i {
    font-size: 2.76rem;
}
.index_title_1kvlx {
  font-weight: bold;
  font-size: 1.23rem;
}
.index_desc_tBHzJ p {
  margin-bottom: 0;
}
.index_bordered_UfoLW {
  padding: 0.38rem 0 1rem 0;
  margin-bottom: -0.93rem;
  margin-top: 0rem;
  background-color: #fff;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-radius: 0;
}
.index_bordered_UfoLW .index_digit_1Mxhj {
    font-size: 2.15rem;
    line-height: 2.92rem;
    height: 1.84rem;
    display: block;
    width: auto;
    min-width: 3.07rem;
    margin: 0 0.76rem;
}
.index_bordered_UfoLW .index_digit_1Mxhj i {
      font-size: 1.84rem;
}
.index_bordered_UfoLW .index_title_1kvlx {
    font-weight: bold;
    font-size: 1rem;
}
.index_bordered_UfoLW .index_desc_tBHzJ {
    padding-top: 0;
}
.index_bordered_UfoLW .index_desc_tBHzJ p {
      font-size: 0.92rem;
      margin-bottom: 0.38rem;
      color: #b8beca;
}
.index_bordered_UfoLW.index_default_1pDBO {
    color: #74708d;
    background-color: #fff;
    border-bottom-color: #acb7bf;
}
.index_bordered_UfoLW.index_primary_1ufxk {
    color: #74708d;
    background-color: #fff;
    border-bottom-color: #0190fe;
}
.index_bordered_UfoLW.index_secondary_1bpoq {
    color: #74708d;
    background-color: #fff;
    border-bottom-color: #6a7a84;
}
.index_bordered_UfoLW.index_success_2cePa {
    color: #74708d;
    background-color: #fff;
    border-bottom-color: #46be8a;
}
.index_bordered_UfoLW.index_info_2o2nT {
    color: #74708d;
    background-color: #fff;
    border-bottom-color: #0887c9;
}
.index_bordered_UfoLW.index_warning_2nTVa {
    color: #74708d;
    background-color: #fff;
    border-bottom-color: #f39834;
}
.index_bordered_UfoLW.index_danger_2c7PV {
    color: #74708d;
    background-color: #fff;
    border-bottom-color: #fb434a;
}
.index_headerContainer_1jNeb {
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 10px;
}
.index_headerContainer_1jNeb h1 {
    font: normal normal 500 24px/36px "Noto Sans JP", sans-serif;
    display: inline-block;
    color: #1F2437;
    letter-spacing: 0px;
    opacity: 1;
    margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
.index_headerContainer_1jNeb h1 {
        font: normal normal 500 18px/36px "Noto Sans JP", sans-serif;
}
}
.index_headerContainer_1jNeb .index_topInfoButtonsContainer_2G8-0 {
    display: inline-block;
    margin-bottom: 21px;
    font: normal normal 500 14px/34px "Noto Sans JP", sans-serif;
}
.index_headerContainer_1jNeb .index_topInfoButtonsContainer_2G8-0 > * {
      margin-left: 8px;
}
.index_headerContainer_1jNeb .index_topInfoButtonsContainer_2G8-0 .index_topInfoButton_1ZvNt {
      width: 160px;
      height: 36px;
}
@media screen and (max-width: 767px) {
.index_headerContainer_1jNeb .index_topInfoButtonsContainer_2G8-0 .index_topInfoButton_1ZvNt {
          width: 96px;
          height: 36px;
          font: normal normal 500 12px/34px "Noto Sans JP", sans-serif;
}
}
.index_targetDescription_1Qf5e {
  padding: 14px 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 2px;
  min-height: 48px;
}
@media screen and (max-width: 767px) {
.index_targetDescription_1Qf5e {
      margin-left: -16px;
      margin-right: -16px;
}
}
.index_targetDescription_1Qf5e .index_label_2bHiD {
    display: inline-block;
    font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #787B87;
    margin-right: 6px;
}
@media screen and (max-width: 767px) {
.index_targetDescription_1Qf5e .index_label_2bHiD {
        font: normal normal normal 12px/20px "Noto Sans JP", sans-serif;
}
}
.index_targetDescription_1Qf5e .index_value_Mgkct {
    display: inline-block;
    font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #1F2437;
    margin-right: 32px;
}
@media screen and (max-width: 767px) {
.index_targetDescription_1Qf5e .index_value_Mgkct {
        font: normal normal bold 12px/20px "Noto Sans JP", sans-serif;
        margin-right: 20px;
}
}
.index_targetDescription_1Qf5e .index_value_Mgkct.index_numValue_1Ye9H {
      font: normal normal bold 18px/20px Lato;
      margin-right: 6px;
}
.index_targetDescription_1Qf5e ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.index_targetDescription_1Qf5e ul li {
      display: inline-block;
      margin-right: 20px;
}
.index_rankingModal_2TE4N .index_rankingDataDesc_3MHY7 {
  font: normal normal 500 16px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 8px;
}
.index_rateModal_2TaS2 .ant-modal-title {
  text-align: left;
  font: normal normal normal 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
}
.index_rateModal_2TaS2 .ant-modal-body p {
  text-align: left;
  font: normal normal 500 16px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 8px;
}
.index_rateModal_2TaS2 .ant-modal-body .index_monthTips_2-Nhp {
  font: normal normal 300 12px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #707070;
}
.index_closeBtn_2JSoO {
  width: 66px;
  height: 36px;
  font: normal normal 300 14px/34px "Noto Sans JP", sans-serif;
  color: #1F2437 !important;
  background-color: #fff;
  position: absolute;
  bottom: -55px;
  right: 0px;
  text-align: center;
  box-shadow: 0px 1px 2px #0000001A;
  border: 1px solid #787B87;
  border-radius: 4px;
}
.index_closeBtn_2JSoO:hover {
    background: #EFEFF7 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #0000001A;
    border: 1px solid #5068C2;
    border-radius: 4px;
}
table.index_newTable_XGeqb {
  text-align: center;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #EFF0F3;
}
table.index_newTable_XGeqb thead {
    background: #EFF0F3 0% 0% no-repeat padding-box;
    font: normal normal 500 14px/33px "Noto Sans JP", sans-serif;
}
@media screen and (max-width: 767px) {
table.index_newTable_XGeqb thead {
        font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
table.index_newTable_XGeqb thead tr {
      height: 48px;
}
table.index_newTable_XGeqb tbody tr {
    height: 48px;
    font: normal normal 300 14px/33px "Noto Sans JP", sans-serif;
    border-bottom: 1px solid black;
    letter-spacing: 0px;
}
@media screen and (max-width: 767px) {
table.index_newTable_XGeqb tbody tr {
        font: normal normal 300 11px/33px "Noto Sans JP", sans-serif;
}
}
table.index_newTable_XGeqb tbody tr.index_stripe0_ysWkB {
      background: #FAFBFC 0% 0% no-repeat padding-box;
}
table.index_newTable_XGeqb tbody tr.index_stripe1_3eBxC {
      background: #FFFFFF 0% 0% no-repeat padding-box;
}
table.index_newTable_XGeqb tbody tr .index_tableValue_212UA {
      font: normal normal bold 16px/33px "Noto Sans JP", sans-serif;
}
@media screen and (max-width: 767px) {
table.index_newTable_XGeqb tbody tr .index_tableValue_212UA {
          font: normal normal bold 14px/33px "Noto Sans JP", sans-serif;
}
}
table.index_newTable_XGeqb tbody tr .index_tableValue_212UA.index_darkGreen_16phT {
        color: #388E3C;
}
table.index_newTable_XGeqb tbody tr .index_tableValue_212UA.index_green_2I2jl {
        color: #8BC34A;
}
table.index_newTable_XGeqb tbody tr .index_tableValue_212UA.index_yellow_KAaKd {
        color: #FFC107;
}
table.index_newTable_XGeqb tbody tr .index_tableValue_212UA.index_red_wAxQP {
        color: #EF5350;
}
.index_filterDrawer_yf9n9 .ant-drawer-title {
  font: normal normal normal 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
}
.index_filterDrawer_yf9n9 .ant-input, .index_filterDrawer_yf9n9 .ant-select-selection {
  color: #1F2437;
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
}
.index_filterDrawer_yf9n9 .ant-radio-button-wrapper {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8DA;
  border-radius: 4px;
  font: normal normal normal 14px/34px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87 !important;
}
.index_filterDrawer_yf9n9 .ant-drawer-body {
  padding: 20px;
}
.index_filterDrawer_yf9n9 .ant-radio-button-wrapper-checked {
  background: #EFEFF7 0% 0% no-repeat padding-box;
  border: 1px solid #5068C2 !important;
  border-radius: 4px 0px 0px 4px;
  color: #5068C2 !important;
}
.index_filterDrawer_yf9n9 h2 {
  font: normal normal 500 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
}
.index_filterDrawer_yf9n9 .index_filterLabel_2a8XH {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
.index_filterDrawer_yf9n9 .index_filterLabel_2a8XH .ant-calendar-picker {
    color: #1F2437;
    width: 200px;
}
.index_filterDrawer_yf9n9 .index_emNum_2pTnk {
  font: normal normal bold 18px/20px Lato;
  letter-spacing: 0px;
  color: #1F2437;
}
.index_filterDrawer_yf9n9 .index_description_1T_QG {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
}
.index_filterDrawer_yf9n9 .index_tip_2A1Lq {
  font: normal normal 300 12px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #707070;
}
.index_statisticsModal_2qXuk .ant-modal-title {
  text-align: left;
  font: normal normal normal 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
}
.index_statisticsModal_2qXuk .index_statisticsTitle__YWhL {
  font: normal normal 500 24px/27px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .ant-modal-body {
    padding: 0;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT {
  border: 1px solid #E6E6E7;
  padding: 24px 40px;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT {
      padding: 24px 16px;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV {
    background: #FAFBFC 0% 0% no-repeat padding-box;
    border: 1px solid #E6E6E7;
    border-radius: 4px;
    padding: 20px;
    font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #787B87;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV {
        padding: 16px;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 {
      display: inline-block;
      width: 50%;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 {
          width: 100%;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 .index_infoLabel_3JZb3 {
        display: inline-block;
        width: 100px;
        margin: 8px 0;
        position: relative;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 .index_infoLabel_3JZb3::after {
          content: ":";
          position: absolute;
          left: 88px;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 .index_infoLabel_3JZb3::after {
              left: 90px;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 .index_infoValue_8wKEg {
        display: inline-block;
        font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 .index_infoValue_8wKEg {
            max-width: 60%;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_leftInfo_ce6j0 .index_infoValue_8wKEg br {
              display: inline;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN {
      display: inline-block;
      width: 49%;
      border-left: 1px solid #E6E6E7;
      padding-left: 1%;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN {
          width: 100%;
          padding-top: 15px;
}
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN {
          border-left: none;
          border-top: 1px solid #E6E6E7;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN .index_infoLabel_3JZb3 {
        display: inline-block;
        width: 100px;
        margin: 8px 0;
        position: relative;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN .index_infoLabel_3JZb3::after {
          content: ":";
          position: absolute;
          left: 88px;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN .index_infoLabel_3JZb3::after {
              left: 90px;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN .index_infoValue_8wKEg {
        display: inline-block;
        font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN .index_infoValue_8wKEg {
            max-width: 60%;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_rightInfo_1vnkN .index_infoValue_8wKEg br {
              display: inline;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_filterInfoArea_2sjsV .index_infoSep_2S_K3 {
      font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
      color: #787B87;
      vertical-align: top;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT h4 {
    font: normal normal bold 16px/20px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #1F2437;
    border-left: 3px solid #1F2437;
    padding-left: 8px;
    margin-top: 32px;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB {
    padding: 20px;
    background: rgba(56, 142, 60, 0.1) 0% 0% no-repeat padding-box;
    border-top: 2px solid #388E3C;
    margin-bottom: 8px;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_percentContainer_1RSXY {
      display: inline-block;
      width: 50%;
      text-align: left;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_percentContainer_1RSXY {
          display: block;
          width: 100%;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_percentContainer_1RSXY .index_percentLabel_2XkO8 {
        font: normal normal bold 18px/20px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_percentContainer_1RSXY .index_percentLabel_2XkO8 {
            font: normal normal bold 16px/16px "Noto Sans JP", sans-serif;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_percentContainer_1RSXY .index_percentValue_1KaQs {
        font: normal normal normal 28px/34px Lato;
        letter-spacing: 1.4px;
        color: #1F2437;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_percentContainer_1RSXY .index_percentValue_1KaQs {
            font: normal normal normal 26px/32px Lato;
            letter-spacing: 1.3px;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_numContainer_3FVUX {
      display: inline-block;
      width: 50%;
      text-align: right;
      letter-spacing: 0px;
      color: #787B87;
}
@media screen and (max-width: 767px) {
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_numContainer_3FVUX {
          display: block;
          width: 100%;
          margin-top: 15px;
}
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_numContainer_3FVUX i {
        font-size: 16px;
        margin-right: 6px;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_numContainer_3FVUX .index_numLabel_3IhDX {
        font: normal normal normal 12px/20px "Noto Sans JP", sans-serif;
        margin-right: 2px;
}
.index_statisticsModal_2qXuk .index_statisticsFrame_34ACT .index_percentArea_11nVB .index_numContainer_3FVUX .index_numValue_1Ye9H {
        font: normal normal normal 16px/19px Lato;
}
