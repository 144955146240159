
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after,
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after,
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after,
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  border-color: black !important;
  border-width: 2px 0 0 2px;
}
.ant-calendar-header .ant-calendar-prev-month-btn { left: 36px;
}
.ant-calendar-header .ant-calendar-next-month-btn { right: 36px;
}
.card.card-mini .card-header {
  padding-top: 0.75rem;
  padding-bottom: 0.45rem;
  padding-left: 1.6rem;
}
.card.card-mini .card-body {
  padding-top: 1.4rem;
  padding-bottom: 1.3rem;
  padding-left: 1.6rem;
}
.card.card-mini .card-body .mb0 {
  margin-bottom: 0;
}
.card.card-mini .utils__title {
  color: #fff;
}
.card-mini.danger .card-header { background: #FF5050;
}
.card-mini.danger .card-body .value { color: #FF5050;
}
.card-mini.warning .card-header { background: #FFB24C;
}
.card-mini.warning .card-body .value { color: #FFA329;
}
.card-mini.check .card-header { background: #185D9A;
}
.card-mini.check .card-body .value { color: #185D9A;
}
.card-mini.success .card-header { background: #0C8CFE;
}
.card-mini.success .card-body .value { color: #0C8CFE;
}
.card.card-mini .card-body {
  padding-top: 1.4rem;
  padding-bottom: 1.3rem;
  padding-left: 1.6rem;
}
.card.card-mini .card-body .mb0 {
  margin-bottom: 0;
}
.card.card-mini .utils__title {
  color: #fff;
}
.card.card-tight .card-header {
  padding-top: 0.75rem;
  padding-bottom: 0.45rem;
  padding-right: 0.3rem;
}
.card.card-tight.card-header-icon .card-header {
  padding-left: 0;
}
.card.card-tight .card-header .notice-icon {
  margin-left: 0.75rem;
  margin-right: 3px;
  width: 30px;
  padding: 12px;
  color: #fff;
}
.card.card-tight .card-body {
  padding-top: 0.4rem;
  padding-bottom: 1.3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.card.card-tight .card-body .mb0 {
  margin-bottom: 0;
}
.card-tight.danger .card-header .notice-icon { background: #FF5050;
}
.card-tight.danger .card-body .value { color: #FF5050;
}
.card.card-tight.danger { border: 1px solid #FF5050;
}
.card-tight.warning .card-header .notice-icon { background: #FFB24C;
}
.card-tight.warning .card-body .value { color: #FFA329;
}
.card.card-tight.warning { border: 1px solid #FFA329;
}
.card-tight.check .card-header .notice-icon { background: #166E4B;
}
.card-tight.check .card-body .value { color: #166E4B;
}
.card.card-tight.check { border: 1px solid #166E4B;
}
.card-tight.success .card-header .notice-icon { background: #20C859;
}
.card-tight.success .card-body .value { color: #20C859;
}
.card.card-tight.success { border: 1px solid #20C859;
}
.table.ranking tr:first-child td { padding-top: 0;
}
.table.ranking td {
  padding-bottom: 0.1rem;
  padding-top: 0.9rem;
}
.table.ranking .rank-border-danger { border-bottom: 1px solid #FF5050;
}
.table.ranking .rank-border-danger .value { color: #FF5050;
}
.table.ranking .rank-border-warning { border-bottom: 1px solid #FFA329;
}
.table.ranking .rank-border-warning .value { color: #FFA329;
}
.table.ranking .rank-border-check { border-bottom: 1px solid #166E4B;
}
.table.ranking .rank-border-check .value { color: #166E4B;
}
.table.ranking .rank-border-success { border-bottom: 1px solid #20C859;
}
.table.ranking .rank-border-success .value { color: #20C859;
}
@media (max-width: 540px) {
.ant-drawer-content-wrapper { width: 80% !important;
}
}
.only-print { display: none !important;
}
@media print {
.no-print { display: none;
}
.ant-modal { width: 100%;
}
.ant-modal-header, .ant-modal-footer, .ant-modal-close { display: none;
}
.only-print { display: block !important;
}
.card { margin-bottom: 1rem;
}
.card-boty {
    padding-top: 1rem;
    padding-bottom: 0.4rem;
}
}

