h1.index_pageTitle_1pCgS {
  font: normal normal 500 24px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 21px;
}
h2.index_cardTitle_39p16, .card > .card-header > h2 {
  padding: 10px 20px;
  font: normal normal 500 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  border-left: 4px solid #5068C2;
}
.ant-btn {
  font: normal normal 500 14px/30px "Noto Sans JP", sans-serif;
}
.ant-btn.index_filledBtn_3qAR9 {
    background: #5068C2 0% 0% no-repeat padding-box !important;
    border-style: none !important;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal 500 14px/30px "Noto Sans JP", sans-serif !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    padding: 0 18px;
}
.ant-btn.index_filledBtn_3qAR9 span {
      color: #FFFFFF !important;
}
.ant-btn.index_filledBtn_3qAR9:hover {
      opacity: 0.2;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_3qAR9) {
    color: #5068C2 !important;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8DA;
    border-radius: 4px;
    opacity: 1;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_3qAR9):hover {
      background: #EFEFF7 0% 0% no-repeat padding-box;
      border: 1px solid #5068C2 !important;
      border-radius: 4px;
      opacity: 1;
}
.ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f2f4f8 !important;
    box-shadow: none !important;
    pointer-events: none;
}
.ant-popover-buttons button {
  line-height: 24px !important;
}
.ant-pagination-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8DA;
  border-radius: 4px;
}
.ant-pagination-item:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #5068C2;
    border-radius: 4px;
}
.ant-pagination-item-active {
  background: #EFEFF7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #5068C2;
}
.ant-list-pagination {
  text-align: center;
}
.ant-pagination {
  text-align: center !important;
}
.ant-list-item:hover {
  background: #EFEFF7 0% 0% no-repeat padding-box;
}
.ant-list-item .ant-list-item-action-split {
  width: 0;
}
.card > .card-header {
  padding-left: 0 !important;
}
.card .card-body {
  padding: 16px !important;
}
@media screen and (max-width: 767px) {
.card .card-body {
      padding-left: 12px !important;
}
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner {
  border-color: #BBBBBB !important;
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner::after {
    border-color: #BBBBBB !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner {
  border-color: #5068C2 !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner::after {
    background-color: #5068C2 !important;
    border-color: #5068C2 !important;
}
.ant-tabs-nav {
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  color: #3042A0;
  font: normal normal 500 14px/20px "Noto Sans JP", sans-serif;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3042A0;
    background: #EFEFF7 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
}
.ant-tabs-nav .ant-tabs-tab-active {
    background: #3042A0 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    color: #FFF;
}
.ant-tabs-nav .ant-tabs-tab-active:hover {
      background: #3042A0 0% 0% no-repeat padding-box;
      color: #FFF;
}
.ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #3042A0 !important;
}
.ant-checkbox-group .ant-checkbox-wrapper {
  font: normal normal normal 14px/33px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
    border: 1px solid #BBBBBB;
    border-radius: 2px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5068C2;
    border-color: #5068C2;
}
.ant-form .ant-form-item > .ant-form-item-label > label {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
span.index_requiredMark_2UY0b {
  display: inline-block;
  width: 36px;
  height: 20px;
  background: #EF5350 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
  font: normal normal normal 11px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  margin: 8px;
  padding: 1px 6px;
}
.ant-table-wrapper table {
  table-layout: fixed;
  word-break: break-all;
  border-collapse: separate;
  border-radius: 4px;
  border: none !important;
}
.ant-table-wrapper table th {
    border: none !important;
}
.ant-table-wrapper tr {
  font: normal normal 300 14px/14px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-table-wrapper tr.index_stripe0_1809i {
    background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe0_1809i td.ant-table-column-sort {
      background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_2VADU {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_2VADU td.ant-table-column-sort {
      background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr td {
    border: none;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter {
  vertical-align: top;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter .on {
    color: #5068C2 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-small .ant-table-body {
    margin: 0;
}
.ant-table-small .ant-table-body table {
      border-collapse: separate;
      border-radius: 4px;
}
.ant-table-small .ant-table-body table th {
        font: normal normal 500 12px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
        background: #EFF0F3 0% 0% no-repeat padding-box !important;
        height: 36px !important;
        padding: 0px 10px !important;
        border: none !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table th {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table td {
        font: normal normal 300 14px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        padding: 0px 10px !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table td {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table tr.index_stripe_GjJaj {
        background: #FAFBFC 0% 0% no-repeat padding-box;
}
.index_title_24Kbn {
  display: inline-block;
  font: normal normal normal 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-left: 16px;
}
.index_green_1SAhC {
  color: #8BC34A;
}
.index_bgGreen_1yJgK {
  background: #8BC34A 0% 0% no-repeat padding-box;
}
.index_shadowGreen_3LDvR {
  box-shadow: 0px 2px 1px #8BC34A !important;
}
.index_darkGreen_3RUEQ {
  color: #388E3C;
}
.index_bgDarkGreen_LWvAV {
  background: #388E3C 0% 0% no-repeat padding-box;
}
.index_shadowDarkGreen_3OhnA {
  box-shadow: 0px 2px 1px #388E3C !important;
}
.index_yellow_3sGz1 {
  color: #FFC107;
}
.index_bgYellow_2ul3h {
  background: #FFC107 0% 0% no-repeat padding-box;
}
.index_shadowYellow_1vc5B {
  box-shadow: 0px 2px 1px #FFC107 !important;
}
.index_red_16YCl {
  color: #EF5350;
}
.index_bgRed_N1Eay {
  background: #EF5350 0% 0% no-repeat padding-box;
}
.index_shadowRed_3I_ex {
  box-shadow: 0px 2px 1px #EF5350 !important;
}
.index_bgGray_2LHKA {
  background: #F4F4F6 0% 0% no-repeat padding-box;
}
.index_shadowGray_KcSNW {
  box-shadow: 0px 2px 1px #F4F4F6 !important;
}
.index_statusIcon_2BfWv {
  font-size: 32px;
  position: absolute;
  right: 8px;
  top: 8px;
}
.index_percent_20qQy {
  font: normal normal normal 20px/24px Lato;
  border-radius: 0px 28px 28px 0px;
  opacity: 1;
  padding: 3px 14px;
  width: 160px;
  height: 48px;
  line-height: 58px;
  color: #FFFFFF;
  margin: 12px 0;
}
@media screen and (max-width: 767px) {
.index_percent_20qQy {
      width: 249px;
}
}
.index_percent_20qQy .index_intPercent_1vTJA {
    font: normal normal normal 34px/24px Lato;
    vertical-align: 1px;
}
.index_percent_20qQy .index_fracPercent_2kmzl {
    font: normal normal normal 20px/24px Lato;
    vertical-align: 1px;
}
.index_percent_20qQy .index_intCount_26h7L {
    font: normal normal normal 34px/41px Lato;
    color: #787B87;
    vertical-align: 1px;
}
.index_percent_20qQy .index_tailPercent_12xlj {
    color: #FFFFFF;
    margin-left: 6px;
    vertical-align: 1px;
}
.index_percent_20qQy .index_tailCount_oJtnF {
    color: #787B87;
    font: normal normal normal 13px/20px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    margin-left: 6px;
    vertical-align: 1px;
}
.index_numText_3kUID {
  font: normal normal normal 12px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
.index_numText_3kUID > div {
    display: inline-block;
    width: 50%;
    text-align: center;
}
.index_numText_3kUID > div > .index_inlineIcon_1J9ne {
      font-size: 16px;
      margin-right: 4px;
}
.index_numText_3kUID > div > .index_label_3P2aO {
      font: normal normal normal 12px/20px "Noto Sans JP", sans-serif;
}
.index_numText_3kUID > div > .index_label_3P2aO:after {
        content: ':';
        margin: 0 2px;
}
.index_numText_3kUID > div > .index_value_1gAA6 {
      font: normal normal normal 16px/19px Lato;
}
.index_seeMoreButton_3opbE {
  font: normal normal normal 12px/34px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
  text-align: right;
  cursor: pointer;
  margin-top: 30px;
  padding-right: 16px;
}
.index_contentExtraMargin_3Xdi4 {
  margin-top: 24px;
  margin-bottom: 42px;
}
